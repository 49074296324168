import React from "react";

interface SearchResultsComponentProps {
  results: {
    organic: any[];
    sponsored: any[];
    products: any[];
    related: any[];
  };
  setRelatedValues: any;
}

const SearchResultsComponent: React.FC<SearchResultsComponentProps> = ({
  results,
  setRelatedValues,
}) => {
 
  
  return (
    <br/>
  );
};

export default SearchResultsComponent;
