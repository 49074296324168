import React, { useState } from "react";
import { useLocation } from "react-router-dom";


interface SearchComponentProps { }


const SearchComponent: React.FC<SearchComponentProps> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";
  const [query, setQuery] = useState(queryParam);

  const handleSearch = (): any => {
    // window.history.pushState(null, "", `/results?query=${query}`);
    window.location.href = `/results?query=${query}`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
      return true;
    }
    return true;
  };

  return (
    <div className="div-io">
      <input
        type="text"
        placeholder="What do you search for today?"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      <button onClick={handleSearch} style={{ cursor: "pointer", border: "1px solid #FE5E30", backgroundColor: "#FE5E30", borderRadius: "5px", padding: "1rem", paddingLeft: "2rem", paddingRight: "2rem" }}>
        Search
      </button>
    </div>
  );
};

export default SearchComponent;
