import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
import logo4Image from '../img/phone-peak-high-resolution-logo-black-transparent.png';
const AboutUsPage: React.FC = () => {
  const cardStyle: React.CSSProperties = {
    border: "1px solid #ddd",
    borderRadius: "8px",
    margin: "2% 10% 5% 10%",
    padding: "8rem",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const cardContentStyle: React.CSSProperties = {
    textAlign: "left",
  };

  return (
    <div>
      <Header />
      <div style={cardStyle}>
        <h1>About Phone Peak</h1>

        <section>
          <div style={cardContentStyle}>
            <p>
              Welcome to Phone Peak, your premier destination for exploring the dynamic world of smartphones and related technologies. As a dedicated force within the tech industry, Phone Peak is committed to delivering unparalleled insights into the ever-evolving realm of mobile devices.
            </p>
          </div>
        </section>

        <section>
          <div style={cardContentStyle}>
            <h2>Our Mission</h2>
            <p>
              Our mission is to be your trusted companion on the exciting journey through the landscape of phones. We aim to provide you with thought-provoking content that not only keeps you informed about the latest advancements but also sparks curiosity and a deeper understanding of the devices that have become an integral part of our daily lives.
            </p>
          </div>
        </section>

        <section>
          <div style={cardContentStyle}>
            <h2>What Sets Us Apart</h2>
            <p>
              Phone Peak stands out in the crowded space of tech magazines with our commitment to delivering content that is not only informative but also engaging and accessible. We strive to bridge the gap between tech enthusiasts and casual readers, ensuring that everyone can find value in the stories we tell about the incredible world of phones.
            </p>
          </div>
        </section>

        <section>
          <div style={cardContentStyle}>
            <h2>Our Focus: Phones and Beyond</h2>
            <p>
              While our primary focus is on phones, we don't stop there. Phone Peak delves into related technologies, exploring how these innovations shape our lifestyles, impact industries, and pave the way for the future. From cutting-edge features to the societal implications of technological advancements, we cover it all.
            </p>
          </div>
        </section>

        <section>
          <div style={cardContentStyle}>
            <h2>Meet the Team</h2>
            <p>
              Behind Phone Peak is a dedicated team of tech enthusiasts, writers, and editors who are passionate about sharing their insights and knowledge. Our diverse team brings a range of perspectives to ensure that our content is comprehensive, engaging, and relevant to our diverse audience.
            </p>
          </div>
        </section>

        <section>
          <div style={cardContentStyle}>
            <h2>Contact Us</h2>
            <p>
              We'd love to hear from you! Whether you have feedback on our articles, suggestions for topics you'd like us to cover, or you just want to say hello, feel free to reach out. Your input is invaluable in shaping the future of Phone Peak.
            </p>
          </div>
        </section>

        <p className='closing-message'>
          Thank you for joining us on this exciting journey through the fascinating world of phones. At Phone Peak, we are committed to being your trusted source for all things tech. Stay connected, stay informed, and let's explore the peaks of technology together!
        </p>
      </div>
      <div style={{ textAlign: "center", marginTop: "10.7rem" }}>
        <a href="/">
          <img src={logo4Image} alt="Search Center" className="h2logo" />
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
