import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { Home } from "./Pages/Home";
import { Results } from "./Pages/Results";
import PagePrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import AboutUs from "./Pages/AboutUs";
import Contact from "./Pages/Contact";
import PhonesAndHumanityArticle from "./Articles/PhonesAndHumanityArticle";
import CrazyPhoneStoriesArticle from "./Articles/CrazyPhoneStoriesArticle";
import BestSmartphonesArticle from "./Articles/BestSmartphonesArticle";
import HealthAndSmartphonesArticle from "./Articles/HealthAndSmartphonesArticle";
import EvolutionOfCommunicationArticle from "./Articles/EvolutionOfCommunicationArticle";
import SpecialPhonesArticle from "./Articles/SpecialPhonesArticle";
import SmartphonesAndFutureTechArticle from "./Articles/SmartphonesAndFutureTechArticle";
const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/results" element={<Results />} />
      <Route path="/Privacy-Policy" element={<PagePrivacyPolicy />} />
      <Route path="/Terms-Of-Use" element={<TermsOfUse />} />
      <Route path="/About" element={<AboutUs />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/PhonesAndHumanityArticle" element={<PhonesAndHumanityArticle />} />
      <Route path="/CrazyPhoneStoriesArticle" element={<CrazyPhoneStoriesArticle />} />
      <Route path="/HealthAndSmartphonesArticle" element={<HealthAndSmartphonesArticle />} />
      <Route path="/BestSmartphonesArticle" element={<BestSmartphonesArticle />} />
      <Route path="/EvolutionOfCommunicationArticle" element={<EvolutionOfCommunicationArticle />} />
      <Route path="/SpecialPhonesArticle" element={<SpecialPhonesArticle />} />
      <Route path="/SmartphonesAndFutureTechArticle" element={<SmartphonesAndFutureTechArticle />} />
    </Routes>
  );
};

export default App;
