import React from "react";
import "./Footer.css";
import ArticlesMenu from "./ArticlesMenu"; // Make sure to provide the correct path
import carImage1 from '../img/2003.m30.i030.n005.S.c20.1432218662_Realistic_smartphone._Mobile_phone_mockup_with_blank_screen_in_different_isometric_perspective._Vector_isolated_3D.jpg';
import carImage2 from '../img/front-view-telephone-receiver-hanging-from-cord.jpg';
import carImage3 from '../img/electronic-device-balancing-concept.jpg';
import carImage4 from '../img/new-cell-phone-colorful-background.jpg';
import carImage5 from '../img/crazy-man-with-mobile-phone-funny-expression.jpg';
import carImage6 from '../img/portrait-happy-girl-dressed-black-dress-pointing.jpg';
import carImage7 from '../img/office-monochrome-stylish-trendy-composition-black-color-surface-top-view-flat-lay-pure-beauty-usual-things-around-copyspace-ad-close-up-smartphone-s-blank-screen.jpg';
import logo4Image from '../img/phone-peak-high-resolution-logo-white-transparent.png';
import carWImage from '../img/smart_phone_logo_with_floral_frame_nr_64.jpg';


const Footer: React.FC = () => {
  // Example article data
  const recentArticles = [
    {
      date: "2023-10-19",
      title: "Explore the Latest in Smartphone Technology",
      description: " Explore the role of smartphones in fostering connectivity and communication. From 5G capabilities to seamless integration with smart ecosystems, these devices play a pivotal role in keeping us connected in an increasingly digital world.      ",
      imageUrl: carImage6,
      path: '/BestSmartphonesArticle',
    },
    {
      date: "2023-11-22",
      title: "Smartphones, AI, and the Future of Technology",
      description: "Explore the intersection of smartphones, artificial intelligence (AI), and the transformative future of technology. From the evolution of mobile devices to the integration of AI, discover how these advancements will shape our lives and pave the way for new technological paradigms.",
      imageUrl: carImage7,
      path: '/SmartphonesAndFutureTechArticle',
    },
    {
      date: "2023-01-01",
      title: "The Intersection of Health and Smartphones: Navigating Physical and Mental Well-being",
      description: "Dive deep into the complex relationship between smartphones and human health, exploring the profound impact on both physical and mental well-being. From the convenience of health apps to the challenges posed by excessive screen time, discover how these pocket-sized devices shape our lives in unexpected ways. ",
      imageUrl: carImage1,
      path: '/HealthAndSmartphonesArticle',
    },
    {
      date: "2023-02-15",
      title: "The Evolution of Childhood and Subcultures: How Phones Shape Humanity",
      description: "Embark on a thought-provoking journey into the transformative effects of smartphones on childhood experiences and the diverse tapestry of human subcultures. From shaping the way children grow up to influencing the dynamics of subcultures, these pocket-sized devices have become powerful agents of change, for better or for worse.",
      imageUrl: carImage2,
      path: '/PhonesAndHumanityArticle',
    },
    {
      date: "2023-02-27",
      title: "Beyond the Ordinary: Exploring Phones with Flexible Screens and Self-Deleting Capabilities",
      description: "Embark on a journey into the extraordinary realm of smartphones with unique features, including flexible screens and self-deleting capabilities. These cutting-edge devices not only redefine our expectations but also introduce novel possibilities, sparking discussions on privacy, innovation, and the evolving nature of human-device interactions.",
      imageUrl: carImage3,
      path: '/SpecialPhonesArticle',
    },
    {
      date: "2023-04-16",
      title: "From Cave Paintings to Smartphones: The Evolution of Communication and the Quest for Uncharted Realms",
      description: "Journey through the annals of human history, tracing the remarkable evolution of communication from primitive forms to the sophisticated technologies embodied in smartphones. As we marvel at the strides we've made in connecting with one another, we also ponder the uncharted realms and untapped potentials that lie beyond the horizon of current communication technologies.",
      imageUrl: carImage4,
      path: '/EvolutionOfCommunicationArticle',
    },
    {
      date: "2023-05-11",
      title: "VR Games and the Future of Reality: A Paradigm Shift",
      description: "As technology continues to advance, virtual reality (VR) games are poised to redefine our perception of reality. Beyond being immersive entertainment experiences, VR games have the potential to bring about positive changes in various aspects of our lives. Let's delve into the world of VR gaming and explore how it is not only transforming our entertainment but also shaping the future of how we interact with the world.",
      imageUrl: carImage5,
      path: '/VRGamesArticle',
    },



  ];

  return (

    <footer>
      <ArticlesMenu articles={recentArticles} />
      <img src={carWImage} alt="car" style={{ width: "100%", height: "600px", objectFit: "cover", marginLeft:"-20px", opacity:"0.3" }} />
      <a href="/">
        <img src={logo4Image} alt="Search Center" className="mlogo" />
      </a>
      <div className="footer-links">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-of-use">Terms</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
     
    </footer>
  );
};

export default Footer;