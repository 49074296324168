import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import expensiveHotelsImage from '../img/crazy-man-with-mobile-phone-funny-expression.jpg';
import hotel1Image from '../img/tattooed-man-with-t-rex-mask-using-smartphone-while-listening-music-crazy-senior-guy-choosing-playlist-from-mobile-phone-app-technology-trends-madness-costume-concept-focus-face.jpg';
import hotel3Image from '../img/man-pointing-cellphone-looking-camera-with-amazement-surprised-about-mobile-data-tariffs.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br /><br />{part}<br /></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
                <p className='add_text'>Exploring Unusual and Crazy Stories Involving Phones: From Bank Heists to International Scams</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={expensiveHotelsImage} alt="most expensive hotels" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <div className="hotel-images-container">
                <img src={hotel1Image} alt="Expensive Hotel 1" className='mycararticle mycararticle-botm' />
                <img src={hotel3Image} alt="Expensive Hotel 3" className='mycararticle mycararticle-botm' />
            </div>
        </div>
    );
};

const CrazyPhoneStoriesArticle: React.FC = () => {
    const articleTitle = 'Beyond Calls and Texts: Unraveling the Crazy Stories of Phones in Notorious Events';
    const articleContent1 = `
    Phones have become an integral part of our daily lives, but their presence isn't limited to routine calls and texts. In the annals of history, there are tales of phones being at the center of some of the most outrageous and jaw-dropping events. From bank robberies orchestrated through a phone call to elaborate scams that spanned continents, these stories weave a tapestry of intrigue and astonishment.

    **The Infamous Bank Robbery Phone Call:**

    *The Perfect Heist Plot:*
    Delve into the audacious world of a bank robbery planned through a phone call. In a daring plot reminiscent of Hollywood heist films, a group of criminals orchestrated their entire plan over the phone, leaving authorities baffled at the sheer audacity of their communication method.

    *Real-Time Coordination:*
    Explore how the criminals utilized phones not only for planning but also for real-time coordination during the heist. The ability to communicate silently and instantaneously played a pivotal role in the success of their criminal enterprise.

    **Phone-Driven Scams That Shook the World:**

    *International Ponzi Schemes:*
    Uncover the dark underbelly of international phone-driven scams that defrauded unsuspecting victims on a massive scale. From Ponzi schemes promising astronomical returns to elaborate phone-based ruses, these scams exploited the trust placed in telecommunications for illicit gains.

    *The Elaborate Tech Support Scam:*
    Navigate the intricate web of a tech support scam that preyed on individuals' fears and lack of technical knowledge. Perpetrators, posing as tech support agents, leveraged phone calls to convince victims that their computers were compromised, ultimately siphoning off significant sums of money.

    **Phone Calls as Instruments of Espionage:**

    *State-Sponsored Espionage:*
    Step into the realm of state-sponsored espionage, where phones served as crucial instruments for covert communications. Explore how intelligence agencies used encrypted calls and secure lines to carry out classified operations, revealing the high-stakes nature of modern espionage.

    *Corporate Espionage and Insider Trading:*
    Unearth stories of corporate espionage and insider trading orchestrated through clandestine phone calls. The ability to relay confidential information in real-time over the phone allowed perpetrators to gain an unfair advantage in the cutthroat world of business.

    `;
    const articleContent2 = `\
    **Unmasking the Unconventional:**

    *The Phone Booth as a Hideout:*
    Discover the unconventional use of phone booths as hideouts in criminal activities. From quick getaways to covert meetings, these seemingly mundane communication hubs took on a dual role as clandestine spaces for those seeking refuge from the eyes of the law.

    *Prison Inmate Phone Scams:*
    Unravel the intricate phone scams orchestrated by inmates from within prison walls. Despite being behind bars, resourceful individuals exploited phone systems to carry out fraud, coercion, and even orchestrate crimes on the outside.

    *The Dark Side of Social Media Calls:*
    Examine the dark side of social media and its role in facilitating scams and criminal activities. From phishing calls to manipulating individuals through fake personas, the convergence of phones and social platforms has given rise to a new breed of cybercriminals.

    **The Cat-and-Mouse Game Continues:**

    *Technological Countermeasures:*
    Explore the ongoing battle between authorities and criminals as technology evolves. The implementation of advanced monitoring systems, voice recognition, and other technological countermeasures seeks to stay one step ahead of those who exploit phones for nefarious purposes.

    *Learning from the Past:*
    Reflect on how these crazy phone stories have influenced advancements in communication security and law enforcement strategies. The lessons learned from these events continue to shape the way we safeguard our communications in an increasingly interconnected world.

    **Conclusion: The Unpredictable World of Phones:**

    As we navigate the intricate landscape of phone-driven stories, one thing becomes clear – the world of phones is as unpredictable as it is fascinating. From Hollywood-worthy heists to elaborate scams, the stories that unfold through our phones remind us that, in the digital age, the line between the ordinary and the extraordinary is often just a phone call away.
  `;
    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default CrazyPhoneStoriesArticle;