import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import modernInteriorImage from '../img/new-cell-phone-colorful-background.jpg';
import livingRoomImage from '../img/electronic-device-surrounded-by-nature.jpg';
import bedroomImage from '../img/electronic-device-balancing-concept.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br /><br />{part}<br /></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
                <p className='add_text'>Exploring Phones with Flexible Screens and Self-Deleting Capabilities</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={modernInteriorImage} alt="modern interior design" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <div className="interior-images-container">
                <img src={livingRoomImage} alt="Modern Living Room" className='mycararticle ' />
                <img src={bedroomImage} alt="Modern Bedroom" className='mycararticle ' />
            </div>
        </div>
    );
};

const SpecialPhonesArticle: React.FC = () => {
    const articleTitle = 'Beyond the Ordinary: Exploring Phones with Flexible Screens and Self-Deleting Capabilities';
    const articleContent1 = `
    Embark on a journey into the extraordinary realm of smartphones with $unique features$, including flexible screens and self-deleting capabilities. These cutting-edge devices not only redefine our expectations but also introduce novel possibilities, sparking discussions on privacy, innovation, and the evolving nature of human-device interactions.

    **Flexible Screens: A New Frontier:**

    *Revolutionizing Design and Functionality:*
    Explore how smartphones with flexible screens push the boundaries of design and functionality. These devices allow for innovative form factors, bending and folding to adapt to users' needs. From compact portability to expanded screen real estate, flexible screens redefine the traditional smartphone experience.

    *Enhancing User Experience:*
    Delve into the ways in which flexible screens enhance the user experience. Multitasking, immersive gaming, and dynamic content consumption are elevated to new heights as these screens provide unprecedented flexibility, offering a glimpse into the future of interactive and adaptable technology.

    *Challenges and Opportunities:*
    Examine the challenges and opportunities presented by smartphones with flexible screens. From concerns about durability to the potential for groundbreaking applications, the evolution of flexible screen technology sparks discussions on the future trajectory of smartphone design.

    **Self-Deleting Capabilities: Privacy in Focus:**

    *Privacy by Design:*
    Explore the concept of privacy by design with smartphones featuring self-deleting capabilities. These devices prioritize user privacy by allowing messages, photos, and data to automatically disappear after a set period, offering a unique approach to digital confidentiality.

    *Navigating Ethical Considerations:*
    Delve into the ethical considerations surrounding self-deleting capabilities. While these features protect user privacy, they also raise questions about information retention, accountability, and the balance between personal privacy and societal transparency.

    *Innovation in Communication:*
    Discover how self-deleting capabilities influence communication dynamics. From ephemeral messaging to secure data sharing, these features cater to individuals seeking a more transient and controlled digital footprint, reshaping the way we connect and communicate.

    `;
    const articleContent2 = `\
    **Societal Impact and Future Possibilities:**

    *Changing Social Interactions:*
    Reflect on how smartphones with unique features impact social interactions. The ability to shape-shift and prioritize privacy challenges traditional notions of permanence in digital communication, influencing the way individuals express themselves and connect with others.

    *Influence on Digital Culture:*
    Consider the influence of smartphones with flexible screens and self-deleting capabilities on digital culture. Explore how these devices contribute to a culture of impermanence, where users value the ephemeral nature of content and embrace a more dynamic, ever-changing digital landscape.

    *Future Possibilities and Innovations:*
    Anticipate the future possibilities and innovations stemming from smartphones with unique features. From advancements in flexible display technology to further developments in secure and private communication, these devices pave the way for a new era of personalized and adaptive technology.

    **Conclusion: Embracing the Extraordinary:**

    As we embrace smartphones with flexible screens and self-deleting capabilities, it's clear that we stand at the threshold of a technological revolution. These devices challenge conventions, offering a glimpse into a future where our interactions with technology are not only seamless and dynamic but also prioritize individual privacy and expression in unprecedented ways.
  `;

    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default SpecialPhonesArticle;