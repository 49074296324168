import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import hotelImage from '../img/waistup-portrait-goodlooking-blond-girl-yellow-hoodie-promote-smartphone-application-show-mobile.jpg';
import serviceImage from '../img/modern-smartphone-dark-background-copy-space-3d-rendering-3d-illustration.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br /><br />{part}<br /></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
                <p className='add_text'>Exploring the Evolution of Communication: From Cave Paintings to Uncharted Realms</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={hotelImage} alt="hotel" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <img src={serviceImage} alt="hotel services" className='mycararticle mycararticle-botm' />
        </div>
    );
};

const EvolutionOfCommunicationArticle: React.FC = () => {
    const articleTitle = 'From Cave Paintings to Smartphones: The Evolution of Communication and the Quest for Uncharted Realms';
    const articleContent1 = `
    Journey through the annals of human history, tracing the remarkable evolution of communication from primitive forms to the sophisticated technologies embodied in $smartphones$. As we marvel at the strides we've made in connecting with one another, we also ponder the uncharted realms and untapped potentials that lie beyond the horizon of current communication technologies.

    **From Cave Paintings to the Written Word:**

    *Pictorial Communication:*
    Reflect on the beginnings of human communication, where our ancestors expressed themselves through cave paintings and symbolic markings. These early forms laid the foundation for shared narratives and cultural exchange.

    *The Written Word and Scrolls:*
    Explore the transformative impact of the written word, as ancient civilizations developed written languages and communicated through scrolls. The ability to preserve and transmit knowledge across generations marked a significant leap in human communication.

    **The Renaissance of Print and Telegraph:**

    *Printed Press and Mass Communication:*
    Witness the revolutionary impact of the printing press, democratizing access to information and sparking the Renaissance. Newspapers, books, and pamphlets became powerful tools for disseminating ideas on a broader scale.

    *Telegraph and Instantaneous Messaging:*
    Delve into the era of the telegraph, where messages could traverse vast distances in mere moments. The telegraph laid the groundwork for instantaneous long-distance communication, foreshadowing the global connectivity we experience today.

    **The Telephone and Beyond:**

    *Alexander Graham Bell's Invention:*
    Uncover the invention of the telephone by Alexander Graham Bell, a groundbreaking moment that brought real-time voice communication into the lives of people around the world. The telephone became a symbol of connection and unity.

    *Radio, Television, and Mass Media:*
    Explore the rise of radio and television, ushering in the era of mass media. These mediums not only allowed for instant information dissemination but also shaped collective cultural experiences on a global scale.

    `;
    const articleContent2 = `\
    **The Digital Age and Smartphones:**

    *The Internet Revolution:*
    Navigate through the digital age, where the internet emerged as a transformative force in communication. Email, instant messaging, and online forums connected people across continents, laying the groundwork for the interconnected world we inhabit.

    *The Birth of Smartphones:*
    Witness the advent of smartphones, compact devices that amalgamated communication, computing, and connectivity. These pocket-sized marvels brought the world to our fingertips, redefining how we interact, work, and share information.

    *Limitations of Current Communication Technologies:*

    **Telepathic Communication and Missing Dimensions:**
    
    *The Quest for Telepathic Communication:*
    Contemplate the idea of telepathic communication, a realm of communication that transcends spoken or written language. While current technologies facilitate rapid information exchange, the direct sharing of thoughts and emotions remains an elusive frontier.

    *Energetic Dimensions and Connectivity:*
    Ponder the missing energetic dimensions in our current communication technologies. As we communicate through digital signals, we may be missing out on subtle energetic connections that exist in human interactions, such as body language and non-verbal cues.

    *The Distance Dilemma:*
    Explore the paradox of connectivity and distance in the digital age. While smartphones bridge geographical gaps, they also introduce a virtual distance, potentially hindering the depth of human connection that can only be fully realized in face-to-face interactions.

    **Charting Uncharted Realms:**

    *Future Horizons and Unexplored Potentials:*
    Anticipate the future of communication, where technology might evolve to encompass uncharted realms. From advancements in brain-computer interfaces to unlocking latent human potentials, the journey of communication continues into realms yet to be explored.

    *Harmony Between Tech and Humanity:*
    As we marvel at the evolution of communication and ponder its future, the quest for harmony between technology and humanity remains essential. Balancing the convenience of instant connectivity with the richness of authentic human interaction will shape the next chapters in our communication saga.

    **Conclusion: Navigating the Vast Seas of Connectivity:**

    From cave paintings to smartphones, the evolution of communication is a testament to humanity's unyielding quest for connection. As we navigate the vast seas of connectivity, we find ourselves at the intersection of technological marvels and the uncharted realms of telepathic communication and energetic dimensions—a journey that continues to unfold with each passing era.
  `;
    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default EvolutionOfCommunicationArticle;