import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import hotelStoriesImage from '../img/office-monochrome-stylish-trendy-composition-black-color-surface-top-view-flat-lay-pure-beauty-usual-things-around-copyspace-ad-close-up-smartphone-s-blank-screen.jpg';
import mysteriousGuestImage from '../img/modern-smartphone-dark-background-copy-space-3d-rendering-3d-illustration.jpg';
import rooftopPoolImage from '../img/excited-group-friends-using-mobile-phone.jpg';
import roomSwitcherooImage from '../img/man-city-fashion-shoot.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br /><br />{part}<br /></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
                <p className='add_text'>Unbelievable Tales: Crazy Stories from Hotels Worldwide</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={hotelStoriesImage} alt="crazy hotel stories" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <div className="story-images-container">
                <img src={mysteriousGuestImage} alt="Mysterious Guest" className='mycararticle ' />
                <img src={rooftopPoolImage} alt="Rooftop Pool Adventure" className='mycararticle ' />
                <img src={roomSwitcherooImage} alt="Room Switcheroo" className='mycararticle ' />
            </div>
        </div>
    );
};

const SmartphonesAndFutureTechArticle: React.FC = () => {
    const articleTitle = 'Smartphones, AI, and the Future of Technology';
    const articleContent1 = `
    Explore the intersection of $smartphones$, artificial intelligence (AI), and the transformative future of technology. From the evolution of mobile devices to the integration of AI, discover how these advancements will shape our lives and pave the way for new technological paradigms.

    **Intelligent Mobile Devices:**

    Witness the rise of intelligent mobile devices that leverage AI for enhanced functionality. From voice assistants to predictive algorithms, smartphones are becoming smarter and more intuitive, adapting to users' needs in real-time.

    **AI-Powered Innovations:**

    Dive into the world of AI-powered innovations that are redefining the capabilities of smartphones. Explore features such as machine learning-driven photo enhancements, personalized recommendations, and adaptive user interfaces that make the user experience truly unique.

    **The Future Beyond Phones:**

    Contemplate the future where smartphones evolve into multi-functional hubs. From controlling smart homes to serving as central nodes in interconnected ecosystems, these devices are set to play a central role in the broader landscape of emerging technologies.

    `;
    const articleContent2 = `\
    **Transitioning to New Tech:**

    Anticipate the transition from traditional smartphones to new and disruptive technologies. Explore concepts like wearable tech, augmented reality glasses, and implantable devices that could redefine how we interact with information and the digital world.

    **Preparing for Change:**

    As technology evolves, it's crucial to prepare for the changing landscape. $Adaptability$, continuous learning, and staying informed about emerging trends will be key in navigating the transition from conventional smartphones to the next wave of transformative tech.

    **Challenges and Opportunities:**

    Acknowledge the challenges and opportunities that come with the integration of AI and new technologies. From ethical considerations to the potential for groundbreaking innovations, understanding the landscape will be essential for individuals and industries alike.

    **Future-Proofing Skills:**

    In conclusion, as we stand at the crossroads of smartphones, AI, and future tech, consider future-proofing your skills. Embrace learning opportunities in AI, data science, and emerging technologies to stay ahead in a world where continuous adaptation is the key to success.
  `;
    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default SmartphonesAndFutureTechArticle;