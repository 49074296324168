import React from 'react';
import './Header.css';
import logo4Image from '../../img/phone-peak-high-resolution-logo-transparent.png';
import SearchComponent from './SearchBar';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <div className="header">
      <div className="logo-and-menu">
        <a href="/">
          <img src={logo4Image} alt="Search Center" className="mlogo" />
        </a>
        <SearchComponent />
      </div>
      <div className="search-input-container">
       
      </div>
    </div>
  );
};

export default Header;