import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import hotelImage from '../img/portrait-happy-girl-dressed-black-dress-pointing.jpg';
import serviceImage from '../img/polygonal-3d-smartphone-gps-navigation-location-app-travelling-concept-phone-navigator-pin-dark-blue-background-smart-technology-digital-illustration.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br/><br/>{part}<br/></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
            <p className='add_text'>Explore the Latest in Smartphone Technology</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={hotelImage} alt="hotel" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <img src={serviceImage} alt="hotel services" className='mycararticle mycararticle-botm' />
        </div>
    );
};

    const BestSmartphonesArticle: React.FC = () => {
        const articleTitle = 'Best Smartphones and Upcoming Innovations';
        const articleContent1 = `
        Stay ahead in the tech world as we delve into the latest in $smartphone$ technology and upcoming innovations. From cutting-edge features to futuristic advancements, explore the forefront of mobile devices and what the future holds.
    
        **State-of-the-Art Devices:**
    
        Whether it's the latest flagship or groundbreaking mid-range models, discover smartphones that redefine the user experience. Experience the perfect blend of design, performance, and innovative functionalities that cater to the diverse needs of tech enthusiasts.
    
        **Revolutionary Features:**
    
        Elevate your mobile experience with devices that boast $revolutionary features$. From advanced camera capabilities to immersive display technologies, these smartphones push the boundaries of what's possible, providing users with unparalleled convenience and entertainment.
    
        **Upcoming Technologies:**
    
        Get a sneak peek into the future of smartphones with upcoming technologies. From foldable screens to augmented reality integrations, these innovations are set to shape the next generation of mobile devices and redefine the way we interact with technology.
    
        `;
        const articleContent2 = `\
        **Connectivity and Beyond:**
    
        Explore the role of smartphones in fostering connectivity and communication. From 5G capabilities to seamless integration with smart ecosystems, these devices play a pivotal role in keeping us connected in an increasingly digital world.
    
        **Environmental Considerations:**
    
        Beyond features, delve into the environmental considerations of smartphone technology. From sustainable materials to eco-friendly manufacturing processes, the industry is making strides towards a more sustainable and responsible approach to device production.
    
        **Anticipating Future Trends:**
    
        As we explore the world of smartphones, it's intriguing to anticipate future trends. $Artificial intelligence$, enhanced security measures, and personalized user experiences are just a glimpse into what the future holds for these indispensable gadgets.
    
        **Embark on a Tech Journey:**
    
        In conclusion, the ever-evolving world of smartphones invites you to embark on a tech journey filled with innovation and possibilities. Whether you're interested in $cutting-edge features, upcoming trends$, or the environmental impact, stay tuned for an exciting ride in the world of mobile technology.
      `;
      
    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default BestSmartphonesArticle;
