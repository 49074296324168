import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import hotelImage from '../img/front-view-telephone-receiver-hanging-from-cord.jpg';
import airbnbImage from '../img/excited-group-friends-using-mobile-phone.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br/><br/>{part}<br/></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
            <p className='add_text'>Exploring the Impact of Smartphones on Childhood and Human Subcultures</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={hotelImage} alt="hotel" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <img src={airbnbImage} alt="Airbnb stay" className='mycararticle mycararticle-botm' />
        </div>
    );
};

const PhonesAndHumanityArticle: React.FC = () => {
    const articleTitle = 'The Evolution of Childhood and Subcultures: How Phones Shape Humanity';
    const articleContent1 = `
    Embark on a thought-provoking journey into the transformative effects of $smartphones$ on childhood experiences and the diverse tapestry of human subcultures. From shaping the way children grow up to influencing the dynamics of subcultures, these pocket-sized devices have become powerful agents of change, for better or for worse.

    **Transforming Childhood:**

    *Digital Natives and Early Exposure:*
    Explore the concept of digital natives, a generation growing up in a world where smartphones are an integral part of daily life. Delve into the implications of early exposure to technology and its impact on cognitive development, socialization, and the formation of identity.

    *Educational Opportunities and Challenges:*
    Examine how smartphones have revolutionized education for children. From interactive learning apps to access to vast information resources, smartphones offer unprecedented educational opportunities. However, they also pose challenges such as potential distractions and concerns about screen time.

    *Social Dynamics and Connectivity:*
    Uncover the shifts in social dynamics among children in the digital age. From online friendships to the influence of social media on self-esteem, smartphones play a pivotal role in shaping how children form connections and perceive their place in the world.

    **Influence on Human Subcultures:**

    *Digitalization of Subcultures:*
    Witness the digitalization of human subcultures, where smartphones serve as gateways to virtual communities. Explore how niche interests, hobbies, and cultural movements find expression and connectivity in the digital realm, transcending geographical boundaries.

    *Challenges to Traditional Subcultures:*
    Examine the impact of smartphones on traditional subcultures. From changes in communication styles to the redefinition of cultural norms, smartphones present both opportunities for global collaboration and challenges to the preservation of unique subcultural identities.

    *Activism and Global Connectivity:*
    Discover the role of smartphones in driving activism and global connectivity within subcultures. Explore how social media platforms amplify voices, facilitate collaboration, and empower subcultures to advocate for social change and cultural evolution.

    `;
    const articleContent2 = `\
    **Navigating the Intersection:**

    *Balancing Connectivity and Isolation:*
    Reflect on the delicate balance between connectivity and isolation in the smartphone era. While smartphones connect us to vast networks, they can also contribute to feelings of isolation and disconnection from physical surroundings. Explore strategies for fostering meaningful connections in the digital age.

    *Parenting in the Smartphone Era:*
    Consider the challenges faced by parents in navigating their children's relationship with smartphones. From setting digital boundaries to fostering a healthy balance between online and offline activities, parenting in the smartphone era requires adaptability and awareness.

    **The Road Ahead:**

    *Ethical Considerations and Privacy:*
    Delve into ethical considerations surrounding smartphone use, especially concerning children and subcultures. Explore questions of privacy, data security, and the responsible use of technology to ensure a future that respects individual rights and autonomy.

    *Innovation, Adaptation, and Collaboration:*
    Embrace the potential for innovation, adaptation, and collaboration in shaping the future relationship between smartphones, childhood, and human subcultures. Consider how advancements in technology can be harnessed to foster creativity, inclusivity, and positive cultural evolution.

    **Conclusion:**

    As smartphones continue to redefine childhood experiences and reshape the dynamics of human subcultures, it becomes imperative to navigate this evolution with mindfulness and intentionality. The road ahead holds both challenges and opportunities, calling for a collective effort to harness the positive aspects while mitigating potential pitfalls.
  `;

    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default PhonesAndHumanityArticle;