import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";
import SearchResultsComponent from "../Components/SearchResults";

interface Props {}

interface SearchResultData {
  organic: string[];
  sponsored: string[];
  products: string[];
  related: string[];
}

export const Results: React.FC<Props> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";

  const [results] = useState<SearchResultData>({
    organic: [],
    sponsored: [],
    products: [],
    related: [],
  });
  const [query] = useState(queryParam);
  const [nextToken] = useState<string | null>(null);

  // const resetSearchResults = () => {
  //   setResults({
  //     organic: [],
  //     sponsored: [],
  //     products: [],
  //     related: [],
  //   });
  //   setNextToken(null);
  // };

  const handleSearch = async () => {
    try {
      if (!query) return; // Do not proceed with an empty query

      const url = '';
      
      const response = await fetch(url);
      if (response.ok) {
        // const data = await response.json();

        // setResults(null);
       
      } else {
        console.error("Error fetching data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    // if (
    //   !queryParams.get("postback") &&
    //   window.location.href == `/results?query=${query}`
    // ) {
    //   window.location.href = `/results?query=${query}`;
    // }

    const navEvent = new Event("navigation");
    window.dispatchEvent(navEvent);
  };

  const setRelatedValues = async (search: any) => {
    // resetSearchResults();
    window.location.href = `/results?query=${search}`;
    handleSearch();
  };

  useEffect(() => {
    if (query !== undefined) {
      // resetSearchResults();
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <SearchResultsComponent
        results={results}
        setRelatedValues={setRelatedValues}
      />
      {nextToken && (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <button
            style={{
              cursor: "pointer",
              padding: "10px 20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              backgroundColor: "#fff",
              fontSize: "16px",
            }}
            onClick={() => {
              handleSearch();
            }}
          >
            More...
          </button>
        </div>
      )}
      <Footer />
    </>
  );
};
