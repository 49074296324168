import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header/Header';
import foodImage from '../img/2003.m30.i030.n005.S.c20.1432218662_Realistic_smartphone._Mobile_phone_mockup_with_blank_screen_in_different_isometric_perspective._Vector_isolated_3D.jpg';
import londonRestaurantsImage from '../img/6193469.jpg';

interface ArticleProps {
    title: string;
    content1: string;
    content2: string;
}

const Article: React.FC<ArticleProps> = ({ title, content1, content2 }) => {
    const parseBoldText = (text: string): React.ReactNode[] => {
        const boldParts = text.split(/\*\*(.+?)\*\*/);

        return boldParts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}><br/><br/>{part}<br/></strong>;
            } else {
                const linkParts = part.split(/\$(.+?)\$/);

                return (
                    <React.Fragment key={index}>
                        {linkParts.map((segment, segmentIndex) => (
                            <React.Fragment key={`${index}-${segmentIndex}`}>
                                {segmentIndex % 2 === 1 ? (
                                    <a
                                        href={`/results?query=${encodeURIComponent(segment)}`}
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                        key={`${index}-${segmentIndex}`}
                                    >
                                        {segment}
                                    </a>
                                ) : (
                                    segment
                                )}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        });
    };

    return (
        <div className="article">
            <div className='absolute_add'>
            <p className='add_text'>Exploring the Impact of Smartphones on Human Health</p>
            </div>
            <h1>{title}</h1>
            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div className="article-content">
                    {parseBoldText(content1)}
                </div>

                <div style={{ cursor: "pointer" }}>
                    <img src={foodImage} alt="food diversity" className='mycararticle' />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                <div style={{ cursor: "pointer" }}>
                    <div className="article-content">
                        {parseBoldText(content2)}
                    </div>
                </div>
            </div>
            <img src={londonRestaurantsImage} alt="London restaurants" className='mycararticle mycararticle-botm' />
        </div>
    );
};

const HealthAndSmartphonesArticle: React.FC = () => {
    const articleTitle = 'The Intersection of Health and Smartphones: Navigating Physical and Mental Well-being';
    const articleContent1 = `
    Dive deep into the complex relationship between $smartphones$ and human health, exploring the profound impact on both physical and mental well-being. From the convenience of health apps to the challenges posed by excessive screen time, discover how these pocket-sized devices shape our lives in unexpected ways.

    **Physical Health Impacts:**

    *Eye Strain and Digital Fatigue:*
    With the widespread use of smartphones, concerns about eye strain and digital fatigue have become prevalent. The constant exposure to screens can lead to discomfort, headaches, and disrupted sleep patterns, emphasizing the need for responsible screen use.

    *Posture and Musculoskeletal Issues:*
    The ubiquity of smartphones has contributed to poor posture habits, leading to musculoskeletal issues. Prolonged periods of phone use, especially when hunched over, can result in neck and back pain, emphasizing the importance of ergonomic practices.

    *Sleep Disruption:*
    The blue light emitted by smartphone screens can interfere with the body's natural sleep-wake cycle. Explore how managing screen time, especially before bedtime, is crucial for maintaining healthy sleep patterns and overall well-being.

    **Mental Health Considerations:**

    *Social Media Impact:*
    Delve into the impact of social media on mental health. From the pressure of constant connectivity to the comparison culture fostered by these platforms, smartphones play a central role in shaping individuals' perceptions and contributing to mental health challenges.

    *Digital Addiction and Anxiety:*
    Uncover the phenomenon of digital addiction and its correlation with anxiety. Explore how the constant notifications, social validation loops, and the fear of missing out (FOMO) contribute to heightened stress levels and mental health concerns.

    *Mindfulness Apps and Well-being:*
    Despite challenges, smartphones also offer tools for enhancing mental well-being. Discover the positive side with mindfulness apps, mental health resources, and digital platforms that encourage self-care and stress reduction.

    `;
    const articleContent2 = `\
    **Strategies for Healthy Smartphone Use:**

    *Setting Boundaries:*
    Explore the importance of setting boundaries for smartphone use. Implementing designated "tech-free" times and spaces can help create a healthier balance between screen time and real-world interactions.

    *Digital Detox Practices:*
    Delve into the concept of digital detoxing and its potential benefits for mental health. Discover how periodic breaks from smartphones can contribute to improved focus, reduced stress, and enhanced overall well-being.

    **Looking to the Future:**

    *Technological Innovations for Health:*
    Anticipate the role of smartphones in future health innovations. Explore the potential of health-tracking apps, wearable devices, and advancements in telemedicine that could revolutionize the way we manage and monitor our well-being.

    *Educating for Digital Literacy:*
    Recognize the importance of digital literacy education in navigating the complex relationship between smartphones and health. Empowering individuals to make informed choices about their digital habits is key to fostering a healthier tech-human balance.

    **Conclusion:**

    As we navigate the intricate interplay between smartphones and health, it's evident that these devices are double-edged swords. While offering unprecedented convenience and access to information, they also pose challenges to our physical and mental well-being. Striking a balance through conscious use, setting boundaries, and embracing technological innovations for health will be crucial in fostering a harmonious relationship between humans and their pocket-sized companions.
  `;
    return (
        <div>
            <Header />
            <Article title={articleTitle} content1={articleContent1} content2={articleContent2} />
            <Footer />
        </div>
    );
};

export default HealthAndSmartphonesArticle;