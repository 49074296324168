import React from "react";
import "./ArticlesMenu.css";
import { Link } from 'react-router-dom';

interface ArticleMenuItem {
  date: string;
  title: string;
  description: string;
  imageUrl: string;
  path: string;
}

interface ArticlesMenuProps {
  articles: ArticleMenuItem[];
}

const ArticlesMenu: React.FC<ArticlesMenuProps> = ({ articles }) => {
  return (
    <Link to={articles[0].path} className="articles-menu" style={{ cursor: 'pointer' }}>
      <h2>Recent Articles</h2>
      <div className="articles-grid">
        {articles.map((article, index) => (
          <div key={index} className="article-item">
            <img src={article.imageUrl} alt={`Article ${index + 1}`} className="article-image" />
            <div className="article-content">
              <span className="article-date">{article.date}</span><br />
              <Link className="article-title" to={article.path}>{article.title}</Link>
              <p className="article-description">{article.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Link>
  );
};

export default ArticlesMenu;