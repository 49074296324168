import React, { FC } from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
import logo4Image from '../img/phone-peak-high-resolution-logo-black-transparent.png';
import logo24Image from '../img/modern-smartphone-dark-background-copy-space-3d-rendering-3d-illustration.jpg';
const Contact: FC = () => {
  const cardStyle: React.CSSProperties = {
    borderRadius: "8px",
    marginLeft: "15%",
    padding: "4erm",
    width: "100%",
    marginTop: "5rem"

  };
  const imgStyle: React.CSSProperties = {

    width: "60%",
    marginTop: "5rem"

  };

  const cardContentStyle: React.CSSProperties = {
    textAlign: "left",
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>

        <div style={cardStyle}>
          <h1 style={{ color: '#333' }}>Get in Touch with Phone Peak</h1>
          <section>
            <div style={cardContentStyle}>
              <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                Welcome to Phone Peak – your source for the latest news in the smartphone world!
                Whether you have a burning question, an exciting story to share, or just want to connect,
                we're here for you. Feel free to drop us a line or pay us a visit at our office.
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
                Our Address:
              </p>
              <p style={{ fontSize: '14px', color: '#555' }}>
              Simtat HaYekev 4, Jerusalem, Israel, 9574762
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
                Our Phone number:
              </p>
              <p style={{ fontSize: '14px', color: '#555' }}>
              054-5903-462
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
                Our Mail:
              </p>
              <p style={{ fontSize: '14px', color: '#555' }}>
                contact@veracityads.com
              </p>
              <p style={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
                Our Website:
              </p>
              <a style={{ fontSize: '14px', color: '#555' }} href=" https://veracityads.com/" >
                https://veracityads.com/
              </a>
            </div>
          </section>
        </div>

        <div style={{ textAlign: "center" }}>
          <a href="/">
            <img style={imgStyle} src={logo24Image} alt="Search Center" className="hlogo" />
          </a>
        </div>

      </div>
      <div style={{ textAlign: "center", marginTop: "10.7rem" }}>
        <a href="/">
          <img src={logo4Image} alt="Search Center" className="h2logo" />
        </a>
      </div>
      <Footer />
    </>
  );
};

export default Contact;