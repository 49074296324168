import * as React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";


interface Props {}

export const Home: React.FC<Props> = () => {
  return (
    <>
      <Header />
      
      <Footer />
    </>
  );
};
